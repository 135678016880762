import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import DeleteUserPopup from './DeleteUserPopup';
import styles from '../../styles/UserManagement.module.css';
import moment from 'moment';

const UserCard = ({
  user,
  handleImageClick,
  handleRoleChange,
  handleUpdateUser,
  showLoading,
  hideLoading,
  enqueueSnackbar,
  currentUser,
  users,
  setUsers,
}) => {
  return (
    <Card
      style={{
        marginBottom: '16px',
        width: '95%',
        maxWidth: '600px',
        margin: 'auto',
        border: '1px solid #ccc',
        boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
      }}
    >
      <CardContent>
        {/* User Name */}
        <Typography variant="h5" align="center" gutterBottom>
          {user.username}
        </Typography>

        {/* Image and Details */}
        <Grid container spacing={1} style={{ marginBottom: '8px' }}>
          {/* Image */}
          <Grid item xs={6} style={{ textAlign: 'center' }}>
            <img
              src={
                user.imageUrl ||
                'https://www.supereasy.com/wp-content/uploads/2022/09/doctor-1-768x432.png'
              }
              width={100}
              style={{ cursor: 'pointer' }}
              onClick={() => handleImageClick(user)}
              alt="User"
            />
          </Grid>
          {/* Details */}
          <Grid item xs={6}>
            <Typography variant="body2">
              <strong>ID:</strong> {user.id}
            </Typography>
            <Typography variant="body2">
              <strong>Email:</strong> {user.email}
            </Typography>
            <Typography variant="body2">
              <strong>Created At:</strong>{' '}
              {moment(user.createdAt).format('MM/DD/YY hh:mmA')}
            </Typography>
            <Typography variant="body2">
              <strong>Last Modified:</strong>{' '}
              {moment(user.updatedAt).format('MM/DD/YY hh:mmA')}
            </Typography>
          </Grid>
        </Grid>

        {/* Roles */}
        <Typography variant="subtitle1">Roles:</Typography>
        <FormControl
          variant="outlined"
          fullWidth
          className={styles.formControlWide}
        >
          <InputLabel id={`role-select-label-${user.id}`}>Role</InputLabel>
          <Select
            multiple
            labelId={`role-select-label-${user.id}`}
            name={`${user.id}`}
            value={Array.isArray(user.roleId) ? user.roleId : [user.roleId]}
            label="Role"
            onChange={(event) => handleRoleChange(event, user)}
          >
            <MenuItem value={1}>App User</MenuItem>
            <MenuItem value={3}>Admin</MenuItem>
            <MenuItem value={4}>Patient</MenuItem>
            <MenuItem value={5}>Provider</MenuItem>
            <MenuItem value={6}>Care Coordinator</MenuItem>
            {/* Add other roles as needed */}
          </Select>
        </FormControl>

        {/* Actions */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '16px',
          }}
        >
          <Button
            variant="contained"
            onClick={() => handleUpdateUser(user)}
            style={{ marginRight: '8px' }}
          >
            Update
          </Button>
          <DeleteUserPopup
            name={`${user.username}`}
            value={user}
            userId={`${user.id}`}
            showLoading={showLoading}
            hideLoading={hideLoading}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default UserCard;
