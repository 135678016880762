import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

// Styled components for compact table cells and rows
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontWeight: 'bold',
    padding: '4px 8px', // Reduced padding for header cells
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '4px 8px', // Reduced padding for body cells
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function AltColorTable({
  columns,
  rows,
  width,
  height,
  marginLeft,
  selectedSlot,
  onRemove,
  deselectSlot,
  selectSlot,
  slotId,
}) {
  console.log('Rows data in AltColorTable before rendering:', rows);
  rows.forEach((row, index) => {
    console.log(`Row ${index}:`, row);
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <TableContainer
      component={Paper}
      style={{
        border: '1px solid gray',
        marginTop: '1rem',
        width: '50%',
        height: '175px',
        overflowY: 'auto',
        marginLeft: '10%',
        marginRight: '0', // Adjust or remove right margin
        paddingRight: '0',
        ...(isMobile && {
          width: '33%', // Full width on mobile
          height: '450px',
          marginLeft: '0%', // Remove left margin on mobile
          marginRight: '0', // Remove right margin on mobile
        }),
      }}
    >
      <Table aria-label="customized table" size="small">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <StyledTableCell
                key={column.field}
                align={column.align || 'left'}
              >
                {column.headerName}
              </StyledTableCell>
            ))}
            {onRemove && (
              <StyledTableCell align="center">Actions</StyledTableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(
            (
              row,
              index // 'row' is defined here as part of the map function
            ) => (
              <StyledTableRow key={index}>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.field}
                    align={column.align || 'left'}
                  >
                    {column.field === 'settingLocation'
                      ? row[column.field]
                          .split('\n')
                          .map((line, idx) => <div key={idx}>{line}</div>)
                      : row[column.field]}
                  </StyledTableCell>
                ))}
                {onRemove && (
                  <StyledTableCell align="center">
                    <IconButton
                      onClick={() => {
                        console.log('Deselecting slot with ID:', row.slotId);
                        onRemove(row.slotId);
                        deselectSlot(row.slotId);
                      }}
                      aria-label="delete"
                      size="small"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </StyledTableCell>
                )}
              </StyledTableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

AltColorTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      headerName: PropTypes.string.isRequired,
      align: PropTypes.string,
    })
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      slotId: PropTypes.number.isRequired, // Assumed `slotId` is a number, adjust type as necessary
      // other properties...
    })
  ).isRequired,
  onRemove: PropTypes.func.isRequired,
  selectedSlots: PropTypes.object,
  deselectSlot: PropTypes.func.isRequired,
  width: PropTypes.string,
  marginLeft: PropTypes.string,
  height: PropTypes.string,
};

export default AltColorTable;
