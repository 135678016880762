import React, { useEffect, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import EventBus from '../common/EventBus';
import { clearLoading, setLoading } from '../actions/loading';
import { logsService } from '../services';
import ResponsiveTableCard from './UI/ResponsiveTableCard';

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    minWidth: 50,
  },
  {
    field: 'username',
    headerName: 'User Name',
    minWidth: 100,
  },
  {
    field: 'actionBy',
    headerName: 'Action By',
    minWidth: 170,
  },
  {
    field: 'eventType',
    headerName: 'Event Type',
    minWidth: 170,
  },
  {
    field: 'IpAddress',
    headerName: 'IpAddress',
    minWidth: 170,
  },
  {
    field: 'Status',
    headerName: 'Status',
    minWidth: 170,
  },
  {
    field: 'EventTime',
    headerName: 'Event Time',
    minWidth: 170,
  },
];

const Logs = () => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [logs, setLogs] = React.useState([]);
  const [eventTypes, setEventTypes] = React.useState(['']);

  const [, setLogsMaster] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filteredLogs, setFilteredLogs] = React.useState([]); // Filtered and/or sorted logs
  const [filterType, setFilterType] = React.useState('All'); // Default filter type Event type
  const [sortType, setSortType] = React.useState('All'); // Default sort type
  const [filterStatusType, setFilterStatusType] = React.useState('All');
  const [sortTime, setSortTime] = React.useState('descending');
  const [shouldShow, setShouldShow] = React.useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getEventTypes = (logs) => {
    const eventTypes = logs.map((log) => log.eventType);
    return [...new Set(eventTypes)];
  };

  useEffect(() => {
    if (
      // eslint-disable-next-line eqeqeq
      currentUser.id ==
        process.env.REACT_APP_USERID1_FOR_LOGS_AND_USER_MANAGE ||
      // eslint-disable-next-line eqeqeq
      currentUser.id == process.env.REACT_APP_USERID2_FOR_LOGS_AND_USER_MANAGE
    ) {
      setShouldShow(true);
    } else {
      setShouldShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    showLoading();
    logsService
      .getLogs()
      .then(
        (response) => {
          const logsData = response.data.logs[0];
          // Process logsData to include 'EventTime' field
          const processedLogs = logsData.map((log) => ({
            ...log,
            EventTime: moment(log.createdAt).format('MM/DD/YY hh:mmA'),
          }));
          setLogs(processedLogs);
          setEventTypes([...getEventTypes(processedLogs)]);
          setLogsMaster(processedLogs); // If you use this for some other purpose
        },

        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          console.log(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch('logout');
          }
        }
      )
      .finally(() => {
        hideLoading();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let filtered = [...logs];

    // Filtering based on eventType
    if (filterType !== 'All') {
      filtered = filtered.filter((log) => log.eventType.includes(filterType));
    }

    if (sortType !== 'All') {
      filtered = filtered.sort((a, b) => a.username.localeCompare(b.username));
    }

    if (filterStatusType !== 'All') {
      filtered = filtered.filter((log) =>
        log.Status.includes(filterStatusType)
      );
    }

    // Sort based on sortTime value
    if (sortTime === 'ascending') {
      // For ascending order
      filtered = filtered.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
    } else if (sortTime === 'descending') {
      // For descending order (default case)
      filtered = filtered.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    }

    setFilteredLogs(filtered);

    // Reset the pagination to the first page
    setPage(0);
  }, [logs, filterType, sortType, filterStatusType, sortTime]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  return shouldShow ? (
    <div
      className="main-container"
      style={{
        padding: '1px',
        marginTop: '20px',
      }}
    >
      <Grid container spacing={2} sx={{ mb: 3 }}>
        {/* Filter By EventType */}
        <Grid size={{ xs: 6, sm: 6, md: 3, lg: 3, xl: 3 }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Filter By EventType</InputLabel>
            <Select
              label="Filter By EventType"
              onChange={(e) => setFilterType(e.target.value)}
              defaultValue="All"
            >
              <MenuItem value="All">All</MenuItem>
              {eventTypes?.map((eventType, index) => (
                <MenuItem value={eventType} key={index}>
                  {eventType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Sort by User Name */}
        <Grid size={{ xs: 6, sm: 6, md: 3, lg: 3, xl: 3 }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Sort by User Name</InputLabel>
            <Select
              label="Sort by User Name"
              onChange={(e) => setSortType(e.target.value)}
              defaultValue="All"
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="username">User Name</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Filter By Status */}
        <Grid size={{ xs: 6, sm: 6, md: 3, lg: 3, xl: 3 }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Filter By Status</InputLabel>
            <Select
              label="Filter By Status"
              onChange={(e) => setFilterStatusType(e.target.value)}
              defaultValue="All"
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="success">Success</MenuItem>
              <MenuItem value="failure">Failure</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Sort by Event Time */}
        <Grid size={{ xs: 6, sm: 6, md: 3, lg: 3, xl: 3 }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Sort by Event Time</InputLabel>
            <Select
              label="Sort by Event Time"
              onChange={(e) => setSortTime(e.target.value)}
              defaultValue="descending"
            >
              <MenuItem value="descending">Latest</MenuItem>
              <MenuItem value="ascending">Oldest</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Conditional rendering based on screen size */}
      {isMobile ? (
        // Render ResponsiveTableCard for mobile view
        <ResponsiveTableCard
          columns={columns}
          data={filteredLogs.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )}
          isDesktopTableStyled={false}
        />
      ) : (
        // Render existing MUI Table for desktop view
        <TableContainer style={{ marginTop: '20px' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.field}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredLogs
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      const value = row[column.field];
                      return (
                        <TableCell key={column.field} align={column.align}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filteredLogs.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  ) : (
    <div>
      <p>You don't have permission to view it.</p>
    </div>
  );
};

export default Logs;
