import React, { useEffect, useState, useCallback } from 'react';
import {
  Autocomplete,
  Box,
  Switch,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { clearLoading, setLoading } from '../../actions/loading';
import EventBus from '../../common/EventBus';
import { useSelector, useDispatch } from 'react-redux';
import {
  AdminPersonalInfo,
  AdminSystemInfo,
  AdminPracticeInfo,
} from '../Input';
import {
  isDoctor,
  isDoctorAdminSupervisor,
  isSupervisee,
} from '../../common/utils/RoleUtils';
import styles from '../../styles/ProviderConfigHomeStyles.module.css';
import { adminService } from '../../services';
import ResponsiveTabs from '../UI/ResponsiveTabs';

// Define tabs similar to ProviderConfigHome
const tabs = [
  {
    value: 'personal_info',
    title: 'Personal Info',
    description: 'Personal Details',
    label: <span>Personal Info</span>,
  },
  {
    value: 'provider_systems',
    title: 'Systems',
    description: 'System configurations',
    label: <span>Systems</span>,
  },
  {
    value: 'practice_info',
    title: 'Practice Info',
    description: 'Practice-related information',
    label: <span>Practice Info</span>,
  },
];

export default function AdminConfigHome() {
  const [activeTab, setActiveTab] = React.useState('personal_info');
  const [users, setUsers] = React.useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedUser, setSelectedUser] = React.useState();
  const [selectedUserDetail, setSelectedUserDetail] = React.useState();
  const [showActiveAdminOnly, setShowActiveAdminOnly] = useState(true); // Default to true
  const [shouldShow, setShouldShow] = React.useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const getAdmins = async (status) => {
    adminService.getAdmins(status).then(
      (response) => {
        let admins = response?.data ?? [];
        // just sorting needed here no filtering on admin_name
        admins = admins.sort((admin1, admin2) => {
          const name1 = admin1?.admin_name?.split(' ')[1] ?? '';
          const name2 = admin2?.admin_name?.split(' ')[1] ?? '';
          return name1.localeCompare(name2);
        });

        if (isDoctor(currentUser) && !isDoctorAdminSupervisor(currentUser)) {
          admins = admins.filter((admin) => {
            return admin.id === currentUser.id;
          });
        }
        setUsers(admins);
        hideLoading();
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log('_content', _content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    );
  };

  const getAllAdmins = async () => {
    adminService.getAllAdmins().then(
      (response) => {
        let admins = response?.data ?? [];
        // just sorting needed here no filtering on admin_name
        admins = admins.sort((admin1, admin2) => {
          const name1 = admin1?.admin_name?.split(' ')[1] ?? '';
          const name2 = admin2?.admin_name?.split(' ')[1] ?? '';
          return name1.localeCompare(name2);
        });

        if (isDoctor(currentUser) && !isDoctorAdminSupervisor(currentUser)) {
          admins = admins.filter((admin) => {
            return admin.id === currentUser.id;
          });
        }
        setUsers(admins);
        hideLoading();
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log('_content', _content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch('logout');
        }
      }
    );
  };

  useEffect(() => {
    /*
    The below env key is same for both the providers and the admin
    */
    if (
      // eslint-disable-next-line eqeqeq
      currentUser.id == process.env.REACT_APP_USERID1_FOR_PROVIDER_STATUS ||
      // eslint-disable-next-line eqeqeq
      currentUser.id == process.env.REACT_APP_USERID2_FOR_PROVIDER_STATUS
    ) {
      setShouldShow(true);
    } else {
      setShouldShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    showLoading();
    if (showActiveAdminOnly) {
      getAdmins('active');
    } else {
      getAllAdmins();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showActiveAdminOnly]);

  const onTagsChange = async (event, values) => {
    let enObj = users.find((obj) => {
      return obj?.id === values?.id;
    });
    setSelectedUser(enObj);
    if (enObj?.id) {
      const adminDetail = await adminService.getAdminDetail(
        enObj.id,
        enObj.email
      );
      if (adminDetail && adminDetail.data) {
        adminDetail.data.admin.username = enObj.username;
        setSelectedUserDetail(adminDetail?.data?.admin);
      }
    }
  };

  const updateAdminDetail = async () => {
    const adminDetail = await adminService.getAdminDetail(
      selectedUser.id,
      selectedUser.email
    );
    if (adminDetail && adminDetail.data) {
      setSelectedUserDetail(adminDetail?.data?.admin);
    }
  };

  const visibleTabs = tabs; // All tabs are visible for now, no conditions used

  useEffect(() => {
    const validTab = visibleTabs.find((tab) => tab.value === activeTab);
    if (!validTab) {
      setActiveTab(visibleTabs.length > 0 ? visibleTabs[0].value : '');
    }
  }, [visibleTabs, activeTab]);

  const marginTop = !isDoctor(currentUser) && isMobile ? '30px' : '0px';

  return (
    <React.Fragment>
      <div
        className="main-container"
        style={{ marginTop: marginTop, paddingLeft: '2.3rem' }}
      >
        <Grid>
          <Grid size={{ xs: 12 }}>
            {(!isDoctor(currentUser) ||
              isDoctorAdminSupervisor(currentUser)) && (
              <Autocomplete
                sx={{ width: '100%' }}
                disablePortal
                defaultValue={selectedUser}
                id="admin"
                options={users}
                onChange={onTagsChange}
                renderInput={(params) => (
                  <TextField
                    className={styles.widerProviderDropdown}
                    variant="outlined"
                    {...params}
                    name="admin_"
                    label="Admin"
                  />
                )}
                getOptionLabel={(option) => option.admin_name || ''}
              />
            )}
            {isDoctor(currentUser) && !isDoctorAdminSupervisor(currentUser) && (
              <>
                <h3>{currentUser.username}</h3>
                <h5>{currentUser.email}</h5>
              </>
            )}
          </Grid>
        </Grid>

        {selectedUser && (
          <Box className={styles.tabContainer}>
            {/* Use ResponsiveTabs here */}
            <ResponsiveTabs
              tabs={visibleTabs}
              activeTab={activeTab}
              onChange={handleTabChange}
              useUniqueValues={true}
            />

            {activeTab === 'personal_info' && (
              <AdminPersonalInfo
                isProvider={isDoctor(currentUser)}
                isSupervisee={isSupervisee(currentUser)}
                selectedUser={selectedUser}
                selectedUserDetail={selectedUserDetail}
                updateAdminDetail={updateAdminDetail}
                showLoading={showLoading}
                hideLoading={hideLoading}
              />
            )}

            {activeTab === 'provider_systems' && (
              <AdminSystemInfo
                isProvider={isDoctor(currentUser)}
                selectedUser={selectedUser}
                selectedUserDetail={selectedUserDetail}
                showLoading={showLoading}
                hideLoading={hideLoading}
              />
            )}

            {activeTab === 'practice_info' && (
              <AdminPracticeInfo
                isProvider={isDoctor(currentUser)}
                selectedUser={selectedUser}
                selectedUserDetail={selectedUserDetail}
                showLoading={showLoading}
                hideLoading={hideLoading}
              />
            )}
          </Box>
        )}

        {!selectedUser && <div>Please select an Admin.</div>}

        {shouldShow && !selectedUser && (
          <div
            style={{
              flexGrow: 1,
              flexBasis: 'calc(33.333% - 24px)',
              maxWidth: 'calc(33.333% - 24px)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <Switch
              checked={showActiveAdminOnly}
              onChange={(e) => setShowActiveAdminOnly(e.target.checked)}
            />
            <span
              style={{
                fontSize: '1.1rem',
                fontWeight: 'bold',
              }}
            >
              {showActiveAdminOnly ? (
                <span>Show Active Admins</span>
              ) : (
                <span>Show All Admins</span>
              )}
            </span>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
