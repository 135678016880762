import React, { memo } from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

// Customize the TextField to match the original styles
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    height: '40px',
    padding: 0,
    '& fieldset': {
      borderColor: theme.palette.grey[300],
    },
    '&:hover fieldset': {
      borderColor: theme.palette.info.light, // Equivalent to sky-200
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.info.main, // Equivalent to sky-500
    },
    '& input': {
      padding: '8px 12px', // Adjust padding to match original
      color: theme.palette.text.primary,
      '&::placeholder': {
        color: theme.palette.text.disabled,
      },
    },
  },
  '& .MuiFormLabel-root': {
    fontSize: '0.875rem', // Equivalent to text-sm
    fontWeight: '400', // Equivalent to font-normal
    color: theme.palette.text.primary,
  },
}));

const Input = ({ label, type, name, id, value, onChange, placeholder }) => {
  return (
    <div>
      <StyledTextField
        label={label}
        type={type}
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        variant="outlined"
        fullWidth
      />
    </div>
  );
};

export default memo(Input);
