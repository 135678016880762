import React, { useState, useEffect, useCallback, memo } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Box,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import ProviderPersonalInfoNewUser from '../Provider/ProviderPersonalInfoNewUser.js';
import { userService, driveService, supervisorService } from '../../services';
import styles from '../../styles/UserManagement.module.css';
import LoadingDialog from './LoadingDialog.js';
import TestingConfig from '../Config/TestingConfig.js';
import TherapyConfig from '../Config/TherapyConfig.js';
import { createOrUpdateBirthdayEvent } from '../../common/utils/DateUtils.js';

import { useDispatch } from 'react-redux';
import { clearLoading, setLoading } from '../../actions/loading.js';
import { setLight, clearLight } from '../../actions/light.js';
import { useSnackbar } from 'notistack';

const NewUserDialog = ({
  open,
  handleClose,
  setUsername,
  setEmail,
  imageUrl,
  setImageUrl,
  setRole,
  email,
  role,
  username,
  currentUser,
  alert,
  selectedUser,
}) => {
  const dispatch = useDispatch();
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [isProviderInfoComplete, setIsProviderInfoComplete] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [openProviderRole, setOpenProviderRole] = useState(false); // This state is use to manage the openess of provider role dropDown
  const [selectedUserDetailForProvider, setSelectedUserDetailForProvider] =
    useState({}); // This state is use to capture the userDetail entered in providerPersonal Info
  const [
    addNewUserTherapyConfigurationValues,
    setAddNewUserTherapyConfigurationValues,
  ] = useState({}); // This state is use to capture the therapy configuration for new user.

  const [
    addNewUserTestingConfigurationValues,
    setAddNewUserTestingConfigurationValues,
  ] = useState({}); // This state is use to capture the testing configuration for new user.

  const [testingConfigurationFilled, setTestingConfigurationValuesFilled] =
    useState(false); // This useState is boolean and is to keep track whether testing configuration values was filled or not

  const [therapyConfigurationFilled, setTherapyConfigurationValuesFilled] =
    useState(false); // This useState is boolean and is to keep track whether therapy configuration values was filled or not
  const [
    showShouldCreateButtonOnValuesFilled,
    setShouldShowCreationButtonOnValuesFilled,
  ] = useState(); // This useState is responsible for showing the createButton based on values being filled by the user.

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const showLoading = useCallback(() => {
    dispatch(setLoading());
  }, [dispatch]);

  const hideLoading = useCallback(() => {
    dispatch(clearLoading());
  }, [dispatch]);

  // The below two functions for setting the light and clearing the light (Means light loader)
  const showLight = useCallback(() => {
    dispatch(setLight());
  }, [dispatch]);

  const hideLight = useCallback(() => {
    dispatch(clearLight());
  }, [dispatch]);

  const handleChange = (event) => {
    const newRole = event.target.value;

    // if new role is of supervisor or supervisee (Provider role will be selected automatically)
    if (newRole === 7 || newRole === 8) {
      setRole([newRole, 5]);
    } else {
      setRole([newRole]);
    }

    setOpenProviderRole(false);
  };

  const handleProviderRoleClose = () => {
    setOpenProviderRole(false);
  };

  const handleProviderRoleOpen = () => {
    setOpenProviderRole(true);
  };

  const afterSubmit = () => {
    handleClose(); // This will close the dialog
  };
  // This useEffect below is responsible for showing the create button on filling out these values with status of provider
  useEffect(() => {
    if (username && email) {
      setShouldShowCreationButtonOnValuesFilled(true);
    }
  }, [email, username]);

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      disableEnforceFocus
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add New User</DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid size={{ xs: 12, sm: 12, md: 6 }}>
            <TextField
              fullWidth
              required
              name="name"
              label="Full Name"
              defaultValue=""
              variant="outlined"
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              helperText="Please enter the full name and for providers include their professional initials e.g. Sigmund Freud, Ph.D."
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 12, md: 6 }}>
            <TextField
              fullWidth
              name="imageUrl"
              label="Image Url"
              defaultValue=""
              variant="outlined"
              onChange={(e) => {
                setImageUrl(e.target.value);
              }}
              helperText="Enter the link to Profile Picture"
            />
          </Grid>

          <Grid size={{ xs: 12, sm: 12, md: 6 }}>
            <TextField
              fullWidth
              required
              autoComplete="off"
              name="email"
              label="Email"
              defaultValue=""
              variant="outlined"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              helperText="Please enter the Email Address"
            />
          </Grid>

          <Grid size={{ xs: 12, sm: 12, md: 6 }}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="role-select-label">Role</InputLabel>
              <Select
                labelId="role-select-label"
                id="role-multi-select"
                value={role}
                open={openProviderRole}
                onOpen={handleProviderRoleOpen}
                onClose={handleProviderRoleClose}
                onChange={handleChange}
                label="Role"
                renderValue={(selected) => (
                  <Box
                    style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}
                  >
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={
                          value === 1
                            ? 'App User'
                            : value === 3
                            ? 'Admin'
                            : value === 4
                            ? 'Patient'
                            : value === 5
                            ? 'Provider'
                            : value === 6
                            ? 'Care Coordinator'
                            : value === 7
                            ? 'Supervisor'
                            : value === 8
                            ? 'Supervisee'
                            : value === 9
                            ? 'Credentialer'
                            : value === 10
                            ? 'Biller'
                            : value === 11
                            ? 'Health Information Manager'
                            : value === 12
                            ? 'Auditor'
                            : value === 13
                            ? 'Claims Manager'
                            : value === 14
                            ? 'Payroll Specialist'
                            : 'Unknown Role'
                        }
                      />
                    ))}
                  </Box>
                )}
              >
                <MenuItem value={1}>App User</MenuItem>
                <MenuItem value={3}>Admin</MenuItem>
                <MenuItem value={4}>Patient</MenuItem>
                <MenuItem value={5}>Provider</MenuItem>
                <MenuItem value={6}>Care Coordinator</MenuItem>
                <MenuItem value={7}>Supervisor</MenuItem>
                <MenuItem value={8}>Supervisee</MenuItem>
                <MenuItem value={9}>Credentialer</MenuItem>
                <MenuItem value={10}>Biller</MenuItem>
                <MenuItem value={11}>Health Information Manager</MenuItem>
                <MenuItem value={12}>Auditor</MenuItem>
                <MenuItem value={13}>Claims Manager</MenuItem>
                <MenuItem value={14}>Payroll Specialist</MenuItem>
                <MenuItem value={15}>Extern</MenuItem>
              </Select>
            </FormControl>
            <span className={styles.messageSupervisee}>
              Upon selecting supervisee or supervisor, provider role will
              automatically be selected
            </span>
          </Grid>
        </Grid>

        {showAdditionalFields && role.includes(5) && (
          <ProviderPersonalInfoNewUser
            isSupervisee={false} // Set this to false if 'isSupervisee' is not relevant
            selectedUser={selectedUser}
            setSelectedUserDetailForProvider={setSelectedUserDetailForProvider}
            createUser={userService.createUser}
            providerName={username}
            onCompletionChange={(isComplete) =>
              setIsProviderInfoComplete(isComplete)
            }
            afterSubmit={afterSubmit}
            renderingThroughNewUser={true} // This Prop will hide the email and the name as we are rendering through new user
          />
        )}

        {/* The below case is for Therapy if inTherapy was true while creating a new user*/}
        {showAdditionalFields &&
          role.includes(5) &&
          isProviderInfoComplete &&
          selectedUserDetailForProvider.inTherapy && (
            <TherapyConfig
              displayingThroughNewuser={true}
              selectedUserDetailForProvider={selectedUserDetailForProvider}
              setAddNewUserTherapyConfigurationValues={
                setAddNewUserTherapyConfigurationValues
              }
              setTherapyConfigurationValuesFilled={
                setTherapyConfigurationValuesFilled
              }
            />
          )}

        {/* The below case is for Testing if inTesting was true while creating a new user*/}
        {showAdditionalFields &&
          role.includes(5) &&
          isProviderInfoComplete &&
          selectedUserDetailForProvider.inTesting && (
            <TestingConfig
              displayingThroughNewuser={true}
              selectedUserDetailForProvider={selectedUserDetailForProvider}
              setAddNewUserTestingConfigurationValues={
                setAddNewUserTestingConfigurationValues
              }
              setTestingConfigurationValuesFilled={
                setTestingConfigurationValuesFilled
              }
            />
          )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>

        {role.includes(5) &&
          showShouldCreateButtonOnValuesFilled &&
          !showAdditionalFields && ( // Check if 'Provider' is one of the roles and additional fields are not shown
            <Button
              onClick={() => setShowAdditionalFields(true)}
              color="secondary"
            >
              Next
            </Button>
          )}

        {(!role.includes(5) ||
          (isProviderInfoComplete &&
            therapyConfigurationFilled &&
            selectedUserDetailForProvider.inTherapy) ||
          (isProviderInfoComplete &&
            testingConfigurationFilled &&
            selectedUserDetailForProvider.inTesting)) && (
          <Button
            onClick={async (e) => {
              try {
                // The below showLight is use to set the color of loader as light
                showLight();
                setIsDialogOpen(true);
                showLoading();
                if (email && role && username) {
                  // Attempt to create the user
                  const response = await userService.createUser({
                    email,
                    role,
                    imageUrl,
                    username,
                    actionBy: currentUser.username,
                    selectedUserDetailForProvider:
                      selectedUserDetailForProvider,
                    therapy: selectedUserDetailForProvider.inTherapy
                      ? addNewUserTherapyConfigurationValues
                      : undefined,
                    testing: selectedUserDetailForProvider.inTesting
                      ? addNewUserTestingConfigurationValues
                      : undefined,
                  });

                  // Check for successful user creation
                  if (
                    response &&
                    response.data &&
                    response.data.msg === 'success'
                  ) {
                    if (
                      response.data.userId &&
                      selectedUserDetailForProvider.primarySuperVisorSelectedId &&
                      selectedUserDetailForProvider.relationshipType &&
                      selectedUserDetailForProvider.primaryStatus
                    ) {
                      // creating user supervisor Relationship
                      await supervisorService.createSupervisorRelationship({
                        superviseeId: response.data.userId,
                        supervisorIdPrimary:
                          selectedUserDetailForProvider.primarySuperVisorSelectedId, // Fetch this ID from the backend
                        relationshipType:
                          selectedUserDetailForProvider.relationshipType,
                        primaryStatus:
                          selectedUserDetailForProvider.primaryStatus, // Include the primary supervisor status
                      });
                    }

                    /* The below is the code for creating birthday event */
                    await createOrUpdateBirthdayEvent(
                      username,
                      selectedUserDetailForProvider.dob
                    );

                    // Copy the provider folder in Google Drive
                    try {
                      const folderResponse =
                        await driveService.copyProviderFolder(
                          response.data.userId
                        );
                      console.log(
                        'Google Drive folder created:',
                        folderResponse
                      );
                    } catch (error) {
                      console.error(
                        'Error creating Google Drive folder:',
                        error
                      );
                    }

                    enqueueSnackbar('User created successfully.', {
                      variant: 'success',
                      timeout: 3000,
                    });

                    handleClose(); // This handleClose closes the popup opened

                    setTimeout(() => {
                      window.location.reload(); // Refresh the page after 2 seconds
                    }, 2000);
                  } else {
                    enqueueSnackbar('Failed to create new user.', {
                      variant: 'error',
                      timeout: 3000,
                    });
                  }
                } else {
                  enqueueSnackbar('Please select required fields.', {
                    variant: 'error',
                    timeout: 3000,
                  });
                }
              } catch (error) {
                enqueueSnackbar('Something went wrong while creating user.', {
                  variant: 'error',
                  timeout: 3000,
                });
              } finally {
                // clearing the light loader
                hideLight();
                setIsDialogOpen(false);
                hideLoading();
              }
            }}
            color="primary"
          >
            Create
          </Button>
        )}
      </DialogActions>

      {/* The below loading Dialog is a light Dialog to be displayed */}
      <LoadingDialog
        open={isDialogOpen}
        message={
          'User is getting created. It may take a while Please holdOn! and do not close the window'
        }
      />
    </Dialog>
  );
};

export default memo(NewUserDialog);
